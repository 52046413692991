// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sitemap-en-js": () => import("./../src/pages/sitemap.en.js" /* webpackChunkName: "component---src-pages-sitemap-en-js" */),
  "component---src-pages-studies-en-js": () => import("./../src/pages/studies.en.js" /* webpackChunkName: "component---src-pages-studies-en-js" */),
  "component---src-pages-study-en-js": () => import("./../src/pages/study.en.js" /* webpackChunkName: "component---src-pages-study-en-js" */)
}

